import React from "react";

const Cover = () => {
  return (
    <div className="d-flex h-100 text-center text-bg-dark bg-desert">
      <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
        <header className="mb-auto">
          <div>
            <h3 className="float-md-start mb-0">Kung Fu Tucson</h3>
            <nav className="nav nav-masthead justify-content-center float-md-end">
              <a
                className="nav-link fw-bold py-1 px-0 active"
                aria-current="page"
                href="/"
              >
                Home
              </a>
              <a
                className="nav-link fw-bold py-1 px-0"
                href="https://kungfutucson.gumroad.com/"
                rel="external"
              >
                Shop
              </a>
              <a
                className="nav-link fw-bold py-1 px-0"
                href="https://www.patreon.com/KungFuTucson"
                rel="external"
              >
                Patreon
              </a>
              <a
                className="nav-link fw-bold py-1 px-0"
                href="https://dancing-dragon-kung-fu.business.site/"
                rel="external"
              >
                Dancing Dragon Kung Fu
              </a>
            </nav>
          </div>
        </header>

        <main>
          <section className="px-3 text-area">
            <h1>Kung Fu Tucson</h1>

            <h2>Content from Sifu Daniel Ross</h2>

            <p className="lead">
              <a
                href="https://www.patreon.com/KungFuTucson?filters[tag]=tai%20chi"
                className="btn btn-lg btn-dark fw-bold border-white"
                rel="external"
              >
                Internal-Style Combat Tai Chi on Patreon
              </a>
            </p>
            <p className="lead">
              <a
                href="https://www.patreon.com/posts/drunken-kung-fu-87302813"
                rel="external"
                className="btn btn-lg btn-dark fw-bold border-white"
              >
                Drunken Kung Fu Seminar on Patreon
              </a>
            </p>
            <p className="lead">
              <a
                href="https://kungfutucson.gumroad.com/l/drunken-kung-fu"
                className="btn btn-lg btn-dark fw-bold border-white"
                rel="external"
              >
                Drunken Kung Fu Seminar on Gumroad
              </a>
            </p>
            <h3>Study with Sifu Daniel Ross Locally</h3>
            <p className="lead">
              <a
                href="https://dancing-dragon-kung-fu.business.site/"
                className="btn btn-lg btn-light fw-bold border-white"
                rel="external"
              >
                Dancing Dragon Kung Fu
              </a>
            </p>
          </section>
        </main>

        <footer className="mt-auto text-white-50"></footer>
      </div>
    </div>
  );
};

export default Cover;
