import React from "react";
import "./App.css";
import Cover from "./Cover";

function App() {
  return (
    <div className="h-100">
      <Cover />
    </div>
  );
}

export default App;
